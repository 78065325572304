import React, { useState } from 'react';
import { Helmet} from 'react-helmet';
import './../css/GraduationPortraits.css';
import img1 from './../pics/KeepsakePortraits/01.jpg';
import img2 from './../pics/KeepsakePortraits/02.jpg';

const KeepsakePortrait = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const portraits = [
    {
      type: 'traditional',
      image: img1,
      alt: 'Professional man in suit with red tie'
    },
    {
      type: 'traditional',
      image: img2,
      alt: 'Graduate in black cap and gown with red stole'
    }
  ];

  const openLightbox = (index) => {
    setCurrentImage(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const nextImage = (e) => {
    e.stopPropagation();
    setCurrentImage((currentImage + 1) % portraits.length);
  };

  const prevImage = (e) => {
    e.stopPropagation();
    setCurrentImage((currentImage - 1 + portraits.length) % portraits.length);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Helmet>
        <title>Keepsake Portrait | MJM Photography</title>
      </Helmet>
      {/* Main Content */}
      <main className="flex-grow max-w-7xl mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-12">
          10x13 Keepsake Portrait
        </h1>

        {/* Spacer */}
        <div className="my-8"></div>

        {/* Portrait Grid */}
        <div className="flex justify-center mb-12">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {portraits.map((portrait, index) => (
              <div key={index} className="bg-white rounded-lg overflow-hidden shadow-3xl">
                <img
                  src={portrait.image}
                  alt={portrait.alt}
                  className="w-full h-auto object-cover cursor-pointer shadow-2xl hover:shadow-3xl transition-shadow duration-200"
                  onClick={() => openLightbox(index)}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Lightbox */}
        {lightboxOpen && (
          <div className="lightbox" onClick={closeLightbox}>
            <div className="lightbox-content">
              <img src={portraits[currentImage].image} alt={portraits[currentImage].alt} className="lightbox-image" />
              <div className="lightbox-controls">
                <button className="lightbox-control prev" onClick={prevImage}>‹</button>
                <button className="lightbox-control next" onClick={nextImage}>›</button>
              </div>
            </div>
          </div>
        )}

        {/* Company Description */}
        
      </main>
    </div>
  );
};

export default KeepsakePortrait;