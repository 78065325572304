import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import Welcome from './Pages/Welcome';
import TraditionalPortraits from './Pages/TraditionalPortraits';
import GraduationPortraits from './Pages/GraduationPortraits';
import Composites from './Pages/Composites';
import KeepsakePortrait from './Pages/KeepsakePortrait';
import Shop from './Pages/Shop';
import ContactUs from './Pages/ContactUs';
import Success from './Pages/Success';
import Header from './header';
import Footer from './footer';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/traditional-portraits" element={<TraditionalPortraits />} />
          <Route path="/graduation-portraits" element={<GraduationPortraits />} />
          <Route path="/composites" element={<Composites />} />
          <Route path="/keepsake-portrait" element={<KeepsakePortrait />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/success" element={<Success />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;