import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from './pics/mjm_photo.svg';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const navItems = [
    { name: 'Welcome', path: '/' },
    { name: 'Traditional Portraits', path: '/traditional-portraits' },
    { name: 'Graduation Portraits', path: '/graduation-portraits' },
    { name: 'Composites', path: '/composites' },
    { name: '10x13 Keepsake Portrait', path: '/keepsake-portrait' },
    { name: 'Shop', path: '/shop' },
    { name: 'Contact Us', path: '/contact-us' }
  ];

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-white bg-opacity-90 shadow-sm sticky top-0 z-10">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <img src={logo} alt="MJM Logo" className="h-10 w-auto" />
            {/* <div className="text-2xl font-bold ml-2">MJM</div> */}
          </div>
          <div className="hidden md:flex space-x-4 mx-auto">
            {navItems.map((item, index) => (
              <Link
                key={index}
                to={item.path}
                className={`px-3 py-2 rounded-md text-sm font-medium ${
                  location.pathname === item.path
                    ? 'text-white bg-gray-500'
                    : 'text-gray-700 hover:text-gray-900 hover:bg-gray-100'
                }`}
              >
                {item.name}
              </Link>
            ))}
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-700 hover:text-gray-900 focus:outline-none"
            >
              <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}></path>
              </svg>
            </button>
          </div>
        </div>
        {isOpen && (
          <div className="md:hidden absolute top-16 left-0 w-full bg-white shadow-lg z-20">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {navItems.map((item, index) => (
                <Link
                  key={index}
                  to={item.path}
                  onClick={handleLinkClick}
                  className={`block px-3 py-2 rounded-md text-base font-medium ${
                    location.pathname === item.path
                      ? 'text-white bg-gray-500'
                      : 'text-gray-700 hover:text-gray-900 hover:bg-gray-100'
                  }`}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;