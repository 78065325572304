import React, { useEffect } from 'react';
import { Helmet} from 'react-helmet';
// import './styles.css';
// import logo from './pics/mjm_photo.png';

const Shop = () => {
  useEffect(() => {
    // Load the external script for the shopping cart
    const script = document.createElement('script');
    script.src = 'https://fineartamerica.com/widgetshoppingcart/widgetscripts.php';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>Shop | MJM Photography</title>
      </Helmet>
      <div className="shop-spacer" id="shop-spacer"></div>
      <iframe
        id="pixelsshoppingcartiframe"
        src="https://fineartamerica.com/widgetshoppingcart/artwork.html?memberidtype=artistid&memberid=470960&domainid=0&showheader=0&height=600&autoheight=true"
        style={{ display: 'inline-block', width: '100%', height: '820px', border: 'none', overflow: 'hidden' }}
        title="Shopping Cart"
      ></iframe>
    </div>
  );
};

export default Shop;