import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';
import emailjs from 'emailjs-com';

const ContactUs = () => {
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue));
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!isEmailValid) {
      alert('Please enter a valid email address.');
      return;
    }

    const requiredFields = ['school', 'name', 'address', 'city', 'state', 'zip', 'email', 'type', 'content'];
    for (let field of requiredFields) {
      if (!e.target[field].value) {
        alert(`Please fill out the ${field} field.`);
        return;
      }
    }

    emailjs.sendForm('service_3dhvr2c', 'template_pxlti3o', e.target, 'h3qTLQDYCzumpuHkQ')
      .then((result) => {
          console.log(result.text);
          window.location.href = 'https://www.mjmphoto.com/success';
      }, (error) => {
          console.log(error.text);
          alert('Message could not be sent.');
      });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12">
      <Helmet>
        <title>Contact Us - MJM Photography, Inc.</title>
      </Helmet>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h1 className="text-2xl font-bold mb-6 text-center">Questions - Reorders - Corrections</h1>
        <p className="mb-6 text-center text-gray-700">
          If you have a question, want to reorder Portraits, or have a problem with an order please fill out the form listed below so we can address your concern!
        </p>
        <form onSubmit={sendEmail}>
          <div className="space-y-4">
            <div>
              <label className="block text-gray-700">School Name</label>
              <input type="text" name="school" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" required />
            </div>
            <div>
              <label className="block text-gray-700">Job #</label>
              <input type="text" name="job" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" />
            </div>
            <div>
              <label className="block text-gray-700">Sequence #</label>
              <input type="text" name="sequence" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" />
            </div>
            <div>
              <label className="block text-gray-700">Name</label>
              <input type="text" name="name" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" required />
            </div>
            <div>
              <label className="block text-gray-700">Address</label>
              <input type="text" name="address" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" required />
            </div>
            <div className="flex space-x-4">
              <div className="flex-1">
                <label className="block text-gray-700">City</label>
                <input type="text" name="city" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" required />
              </div>
              <div className="w-20">
                <label className="block text-gray-700">State</label>
                <input type="text" name="state" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" required />
              </div>
              <div className="w-24">
                <label className="block text-gray-700">Zip</label>
                <input type="text" name="zip" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" required />
              </div>
            </div>
            <div>
              <label className="block text-gray-700">Email Address</label>
              <input 
                type="text" 
                name="email" 
                value={email} 
                onChange={handleEmailChange} 
                className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${isEmailValid ? 'focus:ring-blue-500' : 'border-red-500 focus:ring-red-500'}`} 
                required 
              />
              {!isEmailValid && <p className="text-red-500 text-sm">Please enter a valid email address.</p>}
            </div>
            <div>
              <label className="block text-gray-700">What are you contacting us about:</label>
              <select name="type" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" required>
                <option value="Question">Question - Please Ask</option>
                <option value="Correction">Correction - Nature of Problem</option>
                <option value="Reorder">Reorder - What would you like to reorder</option>
              </select>
            </div>
            <div>
              <label className="block text-gray-700">Message</label>
              <textarea name="content" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" style={{ height: '200px' }} required></textarea>
            </div>
            <div className="flex justify-center">
              <ReCAPTCHA
                sitekey="6Ldqo30qAAAAABpt3OAj6JuPv7eiqz3_lbwDMjxG"
                onChange={handleRecaptchaChange}
              />
            </div>
            <div className="text-right">
              <input type="submit" value="Submit" disabled={!recaptchaValue} className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;