import React, { useState } from 'react';
import { Helmet} from 'react-helmet';
import './../css/GraduationPortraits.css';
import img1 from './../pics/WelcomePage/img1.png';
import img2 from './../pics/WelcomePage/img2.png';
import img3 from './../pics/WelcomePage/img3.png';
import img4 from './../pics/WelcomePage/img4.png';

const Welcome = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const portraits = [
    {
      type: 'traditional',
      image: img1,
      alt: 'Professional man in suit with red tie'
    },
    {
      type: 'graduation',
      image: img2,
      alt: 'Graduate in black cap and gown with red stole'
    },
    {
      type: 'professional',
      image: img3,
      alt: 'Professional woman in business attire'
    },
    {
      type: 'graduation',
      image: img4,
      alt: 'Graduate in black cap and gown with blue tie'
    }
  ];

  const openLightbox = (index) => {
    setCurrentImage(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const nextImage = (e) => {
    e.stopPropagation();
    setCurrentImage((currentImage + 1) % portraits.length);
  };

  const prevImage = (e) => {
    e.stopPropagation();
    setCurrentImage((currentImage - 1 + portraits.length) % portraits.length);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Helmet>
        <title>Welcome | MJM Photography</title>
      </Helmet>
      {/* Main Content */}
      <main className="flex-grow max-w-7xl mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-12">
          MJM Photography, Inc.
        </h1>

        {/* Portrait Grid */}
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-12">
          {portraits.map((portrait, index) => (
            <div key={index} className="bg-white rounded-lg overflow-hidden shadow-3xl">
              <img
                src={portrait.image}
                alt={portrait.alt}
                className="w-full h-auto object-cover aspect-w-3 aspect-h-4 cursor-pointer shadow-2xl hover:shadow-3xl transition-shadow duration-200"
                onClick={() => openLightbox(index)}
              />
            </div>
          ))}
        </div>

        {/* Lightbox */}
        {lightboxOpen && (
          <div className="lightbox" onClick={closeLightbox}>
            <div className="lightbox-content">
              <img src={portraits[currentImage].image} alt={portraits[currentImage].alt} className="lightbox-image" />
              <div className="lightbox-controls">
                <button className="lightbox-control prev" onClick={prevImage}>‹</button>
                <button className="lightbox-control next" onClick={nextImage}>›</button>
              </div>
            </div>
          </div>
        )}

        {/* Company Description */}
        
        <div className="space-y-6 text-center max-w-4xl mx-auto">
          <p className="text-gray-700 leading-relaxed">
            MJM Photography, Inc. began in 1979 as a store front studio operation
            specializing in wedding, senior, and family portraits. In 1983 we began
            serving the school portrait market as well. By 1992 the business
            shifted to serving the college market with uniform consistent portraits
            for the college yearbooks. The store front studio was closed in 1994
            as the college market expanded the business in this new direction.
          </p>
          <p className="text-gray-700 leading-relaxed">
            Although we are located in Greentown, IN we serve colleges and
            universities in Indiana, Ohio, & Illinois. Our goal is to take the
            best traditional and cap & gown portraits in this market.
          </p>
        </div>
      </main>
    </div>
  );
};

export default Welcome;